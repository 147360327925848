exports.components = {
  "component---src-components-about-us-about-us-tsx": () => import("./../../../src/components/about-us/AboutUs.tsx" /* webpackChunkName: "component---src-components-about-us-about-us-tsx" */),
  "component---src-components-blog-blog-category-blog-category-tsx": () => import("./../../../src/components/blog/BlogCategory/BlogCategory.tsx" /* webpackChunkName: "component---src-components-blog-blog-category-blog-category-tsx" */),
  "component---src-components-blog-blog-component-blog-component-tsx": () => import("./../../../src/components/blog/BlogComponent/BlogComponent.tsx" /* webpackChunkName: "component---src-components-blog-blog-component-blog-component-tsx" */),
  "component---src-components-chatbot-demo-chatbot-finance-chatbot-finance-tsx": () => import("./../../../src/components/chatbot-demo/ChatbotFinance/ChatbotFinance.tsx" /* webpackChunkName: "component---src-components-chatbot-demo-chatbot-finance-chatbot-finance-tsx" */),
  "component---src-components-chatbot-demo-recruitment-demo-recruitment-demo-tsx": () => import("./../../../src/components/chatbot-demo/RecruitmentDemo/RecruitmentDemo.tsx" /* webpackChunkName: "component---src-components-chatbot-demo-recruitment-demo-recruitment-demo-tsx" */),
  "component---src-components-chatbot-lp-chatbot-lp-tsx": () => import("./../../../src/components/ChatbotLp/ChatbotLp.tsx" /* webpackChunkName: "component---src-components-chatbot-lp-chatbot-lp-tsx" */),
  "component---src-components-chatbots-page-chatbots-page-tsx": () => import("./../../../src/components/ChatbotsPage/ChatbotsPage.tsx" /* webpackChunkName: "component---src-components-chatbots-page-chatbots-page-tsx" */),
  "component---src-components-contact-us-contact-us-tsx": () => import("./../../../src/components/ContactUs/ContactUs.tsx" /* webpackChunkName: "component---src-components-contact-us-contact-us-tsx" */),
  "component---src-components-document-template-document-template-tsx": () => import("./../../../src/components/DocumentTemplate/DocumentTemplate.tsx" /* webpackChunkName: "component---src-components-document-template-document-template-tsx" */),
  "component---src-components-dynamic-zone-dynamic-page-tsx": () => import("./../../../src/components/DynamicZone/DynamicPage.tsx" /* webpackChunkName: "component---src-components-dynamic-zone-dynamic-page-tsx" */),
  "component---src-components-home-home-tsx": () => import("./../../../src/components/Home/Home.tsx" /* webpackChunkName: "component---src-components-home-home-tsx" */),
  "component---src-components-omega-psir-page-omega-psir-page-tsx": () => import("./../../../src/components/omega-psir-page/OmegaPsirPage.tsx" /* webpackChunkName: "component---src-components-omega-psir-page-omega-psir-page-tsx" */),
  "component---src-components-our-team-our-team-component-our-team-component-tsx": () => import("./../../../src/components/our-team/our-team-component/OurTeamComponent.tsx" /* webpackChunkName: "component---src-components-our-team-our-team-component-our-team-component-tsx" */),
  "component---src-components-our-team-person-page-component-person-page-component-tsx": () => import("./../../../src/components/our-team/person-page-component/PersonPageComponent.tsx" /* webpackChunkName: "component---src-components-our-team-person-page-component-person-page-component-tsx" */),
  "component---src-components-privacy-policy-privacy-policy-tsx": () => import("./../../../src/components/privacy-policy/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-components-privacy-policy-privacy-policy-tsx" */),
  "component---src-components-sages-ai-page-sages-ai-page-tsx": () => import("./../../../src/components/sages-ai-page/SagesAiPage.tsx" /* webpackChunkName: "component---src-components-sages-ai-page-sages-ai-page-tsx" */),
  "component---src-components-training-opinions-training-opinions-tsx": () => import("./../../../src/components/training-opinions/TrainingOpinions.tsx" /* webpackChunkName: "component---src-components-training-opinions-training-opinions-tsx" */),
  "component---src-components-usos-usos-tsx": () => import("./../../../src/components/usos/Usos.tsx" /* webpackChunkName: "component---src-components-usos-usos-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */)
}

